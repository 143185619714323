export const languageMapping = {
    javascript: 63, // Node.js
    python: 71,     // Python 3
    cpp: 54,        // C++
    java: 62,       // Java
    csharp: 51,       // C#
};

export const languageIdMapping = {
    63: 'javascript', // Node.js
    71 : 'python',     // Python 3
    54 : 'cpp',        // C++
    62 : 'java',       // Java
    51 : 'csharp',       // C#
};

export const defaultCode = {
    java: 'import java.util.*;\npublic class Main {\n    public static void main(String[] args) {\n        System.out.println("Hello, CrafterHack!");\n    }\n}',
    javascript: 'process.stdin.resume();\nprocess.stdin.setEncoding("utf-8");\n\nlet inputString = "";\nprocess.stdin.on("data", function(input) {\n    inputString += input;\n});\n\nprocess.stdin.on("end", function() {\n    console.log("Hello, CrafterHack!");\n});',
    python: 'def hello_crafterhack():\n    print("Hello, CrafterHack!")',
    cpp: '#include <iostream>\nusing namespace std;\n\nint main() {\n    cout << "Hello, CrafterHack!" << endl;\n    return 0;\n}',
    csharp: 'using System;\npublic class Program {\n    public static void Main(string[] args) {\n        Console.WriteLine("Hello, CrafterHack!");\n    }\n}'
};


export const PAYMENT_LIVE_KEY = 'rzp_live_HNVta8PtH0jaDD';
export const GOOGLE_SIGN_IN_CLIENT_ID = '571125736587-gtloib4pgjblhppieh0eom3n4194grn7.apps.googleusercontent.com';

export const DEV_INTERVIEW_PRICE = 249;
export const DSA_INTERVIEW_PRICE = 349;
export const PLATFORM_ACCESS_PRICE = 999;
export const DSA_COMPLETE_LEVEL_1_PRICE = 3999;
export const DSA_COMPLETE_LEVEL_2_PRICE = 1499;
export const DSA_REVISE_LEVEL_1_PRICE = 1999;

export const interviewTimeSlots = [
    '09:00 AM',
    '10:00 AM',
    '11:00 AM',
    // '12:00 PM',
    // '02:00 PM',
    '03:00 PM',
    '06:00 PM',
    '08:00 PM',
    '09:00 PM',
    '10:00 PM',
    '11:00 PM',
    'Other'
];
